<template>
  <div class="-mx-3 flow-root">
    <div
      v-for="(destination, index) in destinations"
      :key="destination.id"
      class="venue-tile relative float-left p-3 align-top"
      :class="[`type-${index % 4}`, `i-${index}`, { 'show-more-item': index > showCountDefault - 1 }]"
    >
      <NuxtLink :to="`/${destination.path}`" class="venue-tile-link relative block overflow-hidden rounded-lg pb-100%">
        <div class="absolute size-full bg-gray-400">
          <picture v-if="destination.index_photo">
            <source v-if="destination.index_photo_webp" :srcset="destination.index_photo_webp" type="image/webp">
            <source :srcset="destination.index_photo" type="image/jpeg">
            <img
              draggable="false"
              class="absolute size-full object-cover"
              :src="destination.index_photo"
              :alt="destination.name"
              loading="lazy"
            >
          </picture>
        </div>
        <div class="destination-name font-bw-gradual bg-gradient absolute inset-x-0 bottom-0 p-12 text-4xl font-bold leading-tight text-white">
          <span>{{ destination.name }}</span>
        </div>
      </NuxtLink>
      <div class="venue-quote pointer-events-none invisible absolute inset-0 z-20 flex items-center justify-center p-8 text-center text-lg text-white opacity-0">
        <div>{{ destination.publication_quote }}<br>— {{ destination.publication_quote_source }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    destinations: { type: Array, required: true },
    showCountDefault: { type: Number, default: 4 },
    continents: { type: Array, default: () => [] },
  },
}
</script>

<style lang="scss" scoped>
.bg-gradient {
  background-image: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%)
}

.type-0 {
  width: 41%
}

.type-1 {
  width: 59%;
  > a { padding-bottom: calc(40% - 0.33rem); }
}

.type-2, .type-3 {
  width: 29.5%;
  > a { padding-bottom: calc(55.5% - 0.33rem); }
  .destination-name {
    @apply top-0 flex justify-center align-middle bg-transparent opacity-100;
    transition: opacity 0.3s ease;
    > span { @apply m-auto; }
  }
  &:hover .destination-name { opacity: 0; }
}

@media (max-width: 767px) {
  .type-0, .type-1, .type-2, .type-3 {
    @apply w-full;
    > a { padding-bottom: 62%; }
    .destination-name {
      @apply top-0 flex justify-center align-middle bg-transparent;
      > span { @apply m-auto; }
    }
  }
}

@media (min-width: 768px) and (max-width: 911px) {
  .type-0, .type-1 {
    @apply w-full;
    > a { padding-bottom: 48%; }
  }

  .type-2, .type-3 {
    @apply w-1/2;
    > a { padding-bottom: 100%; }
  }
}

.show-more-item {
  &.type-0, &.type-1, &.type-2, &.type-3 {
    @apply w-1/3;

    @media (max-width: 767px) {
      @apply w-full;
    }

    @media (min-width: 768px) and (max-width: 911px) {
      @apply w-1/2;
    }

    > a { padding-bottom: 50%; }
    .destination-name {
      @apply top-0 flex justify-center align-middle bg-transparent opacity-100;
      transition: opacity 0.3s ease;
      > span { @apply m-auto; }
    }
  }
  &:hover .destination-name { opacity: 0; }
}

.countries-selected {
  .type-0, .type-1, .type-2, .type-3 {
    @apply w-1/3;

    @media (max-width: 767px) {
      @apply w-full;
    }

    @media (min-width: 768px) and (max-width: 911px) {
      @apply w-1/2;
    }

    > a { padding-bottom: 50%; }
    .destination-name {
      @apply top-0 flex justify-center align-middle bg-transparent;
      transition: opacity 0.3s ease;
      > span { @apply m-auto; }
    }
    &:hover .destination-name { opacity: 0; }
  }
}

.venue-tile-link:after {
  content: ''; background-color: rgb(32,32,32); transition: visibility 0.3s ease, opacity 0.3s ease;
  @apply block absolute invisible inset-0 z-10 opacity-0;
}

.venue-quote { transition: visibility 0.3s ease, opacity 0.3s ease; }

.venue-tile:hover {
  .venue-tile-link:after { @apply visible opacity-75; }
  .venue-quote { @apply visible opacity-100; }
}
</style>
